import React, { useMemo, useState } from "react";
import classnames from "classnames";
import Dropdown, { IDropdown } from "../dropdown/Dropdown";
import Search, { ISearch } from "../search/Search";
import ArrowDown from "../../assets/arrow-down.svg";
import "./filter.css";

const STICKY_TOP_SMALL_PX = "57px";
const STICKY_TOP_BIG_PX = "123px";

interface IProps {
  dropdowns?: IDropdown[];
  mobileLabel: string;
  numResults?: number;
  search?: ISearch;
  sticky: boolean;
}

const Filter: React.FC<IProps> = ({
  dropdowns,
  mobileLabel,
  numResults,
  search,
  sticky
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [filterChanges, setFilterChanges] = useState({});

  const showResults = useMemo(() => Object.keys(filterChanges).length > 0, [
    filterChanges
  ]);

  const handleMenuToggle = () => {
    setShowMenu(prevShowMenu => !prevShowMenu);
  };

  const stickyStyles = useMemo(
    () => ({
      left: 0,
      position: "absolute" as "absolute",
      right: 0,
      top: search ? STICKY_TOP_BIG_PX : STICKY_TOP_SMALL_PX
    }),
    [search]
  );

  return (
    <div className="filter-wrapper">
      <div
        className={classnames("filter-menu-wrapper", { open: showMenu })}
        style={sticky ? stickyStyles : {}}
      >
        <div className="filter filter-menu">
          {dropdowns?.length > 0 && (
            <div className="filter-dropdowns">
              {dropdowns.map((dropdown: IDropdown, index: number) => (
                <Dropdown
                  items={dropdown.items}
                  key={index}
                  onItemSelected={item => {
                    dropdown.onItemSelected(item);
                    setFilterChanges(prevFilterChanges => {
                      if (item.id === "") {
                        const nextFilterChanges = { ...prevFilterChanges };
                        delete nextFilterChanges[index];

                        return nextFilterChanges;
                      }

                      return { ...prevFilterChanges, [index]: item.id };
                    });
                  }}
                  placeholder={dropdown.placeholder}
                  selectedItem={dropdown.selectedItem}
                />
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="filter-toggle" onClick={handleMenuToggle}>
        <div className="filter-toggle-label">
          <span>{mobileLabel}</span>
          {showResults && (
            <span className="filter-toggle-results">
              {numResults} result(s)
            </span>
          )}
        </div>
        <ArrowDown className={`filter-toggle-icon ${showMenu && "inverted"}`} />
      </div>
      {search && (
        <div className="filter-search">
          <Search
            items={search.items}
            onItemSelected={search.onItemSelected}
            placeholder={search.placeholder}
          />
        </div>
      )}
    </div>
  );
};

export default Filter;
