import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import classnames from "classnames";
import { SecondaryButton } from "@metyis-ds/button";
import SearchGlass from "../../assets/search.svg";
import { ISearchItem } from "./types";
import "./search.css";

export interface ISearch {
  items: ISearchItem[];
  onItemSelected?: (item: ISearchItem | null) => void;
  placeholder?: string;
}

export const Search: React.FC<ISearch> = ({
  items = [],
  onItemSelected,
  placeholder
}) => {
  const inputRef = useRef(null);
  const [autoCompleteOpen, setAutoCompleteOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedItem, setSelectedItem] = useState<ISearchItem>();

  const filteredSearchResults = useMemo(
    () =>
      searchValue.length > 0
        ? items.filter(
            (item: ISearchItem) =>
              item.text.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
          )
        : [],
    [items, searchValue]
  );

  useEffect(() => setAutoCompleteOpen(filteredSearchResults.length > 0), [
    filteredSearchResults,
    setAutoCompleteOpen
  ]);

  const clearSearch = useCallback(() => {
    setSearchValue("");
    setSelectedItem(null);
    onItemSelected?.(null);
  }, [setSearchValue, setSelectedItem]);

  const focusInput = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (e.target !== inputRef.current) {
        e.preventDefault();
        inputRef.current.focus();
      }
    },
    [inputRef]
  );

  const handleBlur = useCallback(() => setAutoCompleteOpen(false), [
    setAutoCompleteOpen
  ]);

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value),
    [setSearchValue]
  );

  const handleFocus = useCallback(() => {
    setAutoCompleteOpen(filteredSearchResults.length > 0);
  }, [filteredSearchResults, setAutoCompleteOpen]);

  const handleResultSelected = useCallback(
    (result: ISearchItem) => () => {
      setSelectedItem(result);
      onItemSelected?.(result);
      inputRef.current.blur();
    },
    [inputRef, onItemSelected, setSelectedItem]
  );

  const searchResults = useMemo(
    () =>
      filteredSearchResults.map((result: ISearchItem) => (
        <div
          className="search-results-item"
          key={result.id}
          onClick={handleResultSelected(result)}
        >
          <b>{result.text}</b>
          {result.description}
        </div>
      )),
    [filteredSearchResults]
  );

  return (
    <div className="search">
      {selectedItem ? (
        <div className="search-selection">
          <span className="search-selection-item">{selectedItem.text}</span>
          <SecondaryButton
            backgroundColor="var(--color-black)"
            color="var(--color-white)"
            onClick={clearSearch}
          >
            Clear search
          </SecondaryButton>
        </div>
      ) : (
        <div className="search-box" onMouseDown={focusInput}>
          <div
            className={classnames("search-box-input", {
              opened: autoCompleteOpen
            })}
          >
            <input
              onBlur={handleBlur}
              onChange={handleSearchChange}
              onFocus={handleFocus}
              placeholder={placeholder}
              ref={inputRef}
              type="text"
              value={searchValue}
            />
            <SearchGlass />
          </div>
          {autoCompleteOpen && (
            <div className="search-overlay">
              <div className="search-results-border" />
              <div className="search-results">{searchResults}</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default Search;
