import React, { useEffect, useState } from "react";
import { useCheckWindowSize } from "@metyis-ds/hooks";
import { ImageAvatar } from "@metyis-ds/avatar";
import { Link as A } from "@metyis-ds/link";
import { Link } from "../Link/LinkWithPrevUrl";
const getUrl = require("../../helpers/helpers.ts").getUrl;
const getOptimizedImgUrl = require("../../helpers/helpers.ts")
  .getOptimizedImgUrl;
import { IProfile } from "./types";
import FadeinDiv from "../fadeinDiv/FadeinDiv";
import "./profile.css";

interface IProps extends IProfile {
  avatarFilterColor?: string;
  backgroundColor?: string;
  showContact?: boolean;
  showBio?: boolean;
  textColor?: string;
  onProfileHover: (
    avatar: { file: { url: string }; title: string } | undefined
  ) => void;
}

const Profile: React.FC<IProps> = ({
  avatar,
  avatarFilterColor = "var(--color-green-blue)",
  coworkerLocation,
  shortBio,
  jobPosition,
  name,
  showBio = false,
  shouldHideFromList = false,
  textColor = "var(--color-blue-dark)",
  url,
  onProfileHover
}) => {
  const isMobile = useCheckWindowSize(900);
  const [isHovering, setIsHovering] = useState(false);

  useEffect(() => {
    onProfileHover(isHovering ? avatar : null);
  }, [isHovering]);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const profileSummaryEl = (
    <FadeinDiv>
      <div
        className="profile-summary"
        style={{
          color: isHovering ? "var(--color-orange)" : textColor
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <span className="profile-name">{name}</span>
        <span className="profile-title">
          {jobPosition}, {coworkerLocation}
        </span>
        {showBio && shortBio && (
          <span className="profile-description">
            {shortBio.shortBio.length > 140
              ? `${shortBio.shortBio.substring(0, 141)}...`
              : shortBio.shortBio}
          </span>
        )}
        {!shouldHideFromList && (
          <A
            color={isHovering ? "var(--color-orange)" : textColor}
            forceHover={isHovering}
          >
            View profile
          </A>
        )}
      </div>
    </FadeinDiv>
  );

  return shouldHideFromList ? (
    profileSummaryEl
  ) : (
    <Link to={getUrl("profile", url)}>{profileSummaryEl}</Link>
  );
};

export default Profile;
