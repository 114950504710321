import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import { PrimaryButton } from "@metyis-ds/button";
import { useCheckWindowSize, useWindowSize } from "@metyis-ds/hooks";
import { IProfileList } from "./types";
import Divider from "../divider/Divider";
import DoubleLineTitle from "../doubleLineTitle/DoubleLineTitle";
import Profile from "./Profile";
import "./profile.css";
import { ImageAvatar } from "@metyis-ds/avatar";
const getOptimizedImgUrl = require("../../helpers/helpers.ts")
  .getOptimizedImgUrl;

const PROFILE_LOAD_NR = 12;

const ProfileList: React.FC<IProfileList> = ({
  avatarFilterColor,
  backgroundColor,
  borderOnTop,
  coworkers,
  isSpacious = false,
  showContact = false,
  showBio = false,
  textColor,
  title,
  title2,
  titleColor,
  title2Color,
  profileLevel
}) => {
  const windowSize = useWindowSize();
  const isMobile = useCheckWindowSize(680);
  const [numCols, setNumCols] = useState(0);
  const [profileNrLimit, setProfileNrLimit] = useState(PROFILE_LOAD_NR);
  const listRef = useRef<HTMLDivElement>();
  const isProfileListEmpty = !coworkers || coworkers.length === 0;
  const [profileAvatar, setProfileAvatar] = useState<
    { file: { url: string }; title: string } | undefined
  >();

  useEffect(() => {
    listRef.current.style.setProperty(
      "--max-num-cols",
      listRef.current && !isMobile && isSpacious ? "4" : null
    );
  }, [isMobile, listRef.current]);

  useEffect(() => {
    if (typeof window !== "undefined" && listRef.current) {
      const nCols = window
        .getComputedStyle(listRef.current)
        .getPropertyValue("grid-template-columns")
        .split(" ").length;
      setNumCols(nCols);
      setProfileNrLimit(nCols * 6);
    }
  }, [listRef.current, windowSize[0]]);

  useEffect(() => {
    setProfileNrLimit(numCols * 6);
  }, [numCols]);

  const handleLoadMore = () => {
    setProfileNrLimit(prevLimit => prevLimit + numCols * 6);
  };

  return (
    <div className="profiles-wrapper">
      <div className="profiles-header">
        <div className="avatar-sticky">
          <DoubleLineTitle
            className="profiles-title"
            title={
              profileLevel === "Advisory Council" ||
              profileLevel === "Executive Board"
                ? profileLevel
                : `${profileLevel}s`
            }
          />

          {profileAvatar && !isMobile ? (
            <ImageAvatar
              backgroundColor={avatarFilterColor}
              className="profile-img"
              fallbackText={profileAvatar.title}
              img={{
                alt: profileAvatar.title,
                className: "lazyload",
                "data-src": getOptimizedImgUrl({
                  url: profileAvatar.file.url,
                  useFallbackFormat: true
                })
              }}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      <div
        className={classnames("profile-list-wrapper", {
          "profile-list-wrapper--empty": isProfileListEmpty
        })}
        style={{
          backgroundColor,
          color: textColor,
          paddingTop: borderOnTop && 0
        }}
      >
        {borderOnTop && (
          <Divider
            className="divider-list"
            style={{
              borderTop: `1px solid ${textColor}`
            }}
          />
        )}
        <DoubleLineTitle
          className="profile-list-title"
          title={title}
          title2={title2}
          titleColor={titleColor}
          title2Color={title2Color}
        />
        <div className="profile-list" ref={listRef}>
          {coworkers?.map((profile, i) => (
            <Profile
              key={profile.name}
              {...profile}
              avatarFilterColor={avatarFilterColor}
              backgroundColor={backgroundColor}
              showContact={showContact}
              showBio={showBio}
              textColor={textColor}
              onProfileHover={profileAvatar => {
                setProfileAvatar(profileAvatar);
              }}
            />
          ))}
          {isProfileListEmpty && (
            <span style={{ fontSize: 24 }}>No profiles found</span>
          )}
        </div>
        {/* {!isProfileListEmpty && profileNrLimit < coworkers?.length && (
          <div className="show-more">
            <span className="show-more-label">
              Showing {profileNrLimit} out of {coworkers.length}
            </span>
            <PrimaryButton onClick={handleLoadMore}>Load more</PrimaryButton>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default ProfileList;
