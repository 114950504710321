import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import classnames from "classnames";
import { useCheckWindowSize, useScroll } from "@metyis-ds/hooks";
import { HeaderContext } from "../../contexts/HeaderContext";
import Dropdown, { IDropdown } from "../dropdown/Dropdown";
import FilterMobile from "./FilterMobile";
import Search, { ISearch } from "../search/Search";
import "./filter.css";

interface IProps {
  dropdowns?: IDropdown[];
  isSticky?: boolean;
  mobileLabel: string;
  numResults?: number;
  search?: ISearch;
}

const Filter: React.FC<IProps> = ({
  dropdowns,
  isSticky = false,
  mobileLabel,
  numResults,
  search
}) => {
  const scroll = useScroll();
  const isMobile = useCheckWindowSize(900);
  const ref = useRef(null);
  const [sticky, setSticky] = useState(false);
  const [shouldRender, setShouldRender] = useState(false);
  const { stickyHeaderHeight } = useContext(HeaderContext);

  // Avoid problems with ssr and client hydration due to use of isMobile
  // to render different blocks of code
  useEffect(() => {
    setShouldRender(true);
  }, []);

  const stickyDirection = useMemo(() => {
    if (ref.current?.getBoundingClientRect().top === 0) {
      setSticky(true);
    } else if (ref.current?.getBoundingClientRect().top > stickyHeaderHeight) {
      setSticky(false);
    }

    return sticky && scroll.scrollDirection === "up" ? "up" : "";
  }, [
    ref.current?.getBoundingClientRect().top,
    scroll.scrollDirection,
    stickyHeaderHeight
  ]);

  return shouldRender ? (
    <div
      className={classnames("filter", {
        sticky: isSticky,
        [stickyDirection]: isSticky
      })}
      ref={ref}
      style={{
        top:
          isSticky && stickyDirection === "up" ? `${stickyHeaderHeight}px` : 0
      }}
    >
      {isMobile ? (
        <FilterMobile
          dropdowns={dropdowns}
          mobileLabel={mobileLabel}
          numResults={numResults}
          search={search}
          sticky={sticky}
        />
      ) : (
        <>
          {dropdowns?.length > 0 && (
            <div className="filter-dropdowns">
              {dropdowns.map((dropdown: IDropdown, index: number) => (
                <Dropdown
                  items={dropdown.items}
                  key={index}
                  onItemSelected={dropdown.onItemSelected}
                  placeholder={dropdown.placeholder}
                  selectedItem={dropdown.selectedItem}
                />
              ))}
            </div>
          )}
          {search && (
            <div className="filter-search">
              <Search
                items={search.items}
                onItemSelected={search.onItemSelected}
                placeholder={search.placeholder}
              />
            </div>
          )}
        </>
      )}
    </div>
  ) : (
    <></>
  );
};

export default Filter;
