import React, { useCallback, useMemo, useRef, useState } from "react";
import classnames from "classnames";
import ArrowDown from "../../assets/arrow-down-simple.svg";
import ArrowUp from "../../assets/arrow-up-simple.svg";
import { useOnClickOutside } from "@metyis-ds/hooks";
import { IDropdownItem } from "./types";
import "./dropdown.css";

export interface IDropdown {
  items: IDropdownItem[];
  onItemSelected?: (item: IDropdownItem) => void;
  placeholder?: string;
  selectedItem?: IDropdownItem;
}

const Dropdown: React.FC<IDropdown> = ({
  items,
  onItemSelected,
  placeholder = "",
  selectedItem
}) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState<IDropdownItem>(
    selectedItem || null
  );

  useOnClickOutside([ref], () => setIsOpen(false));

  const handleShow = useCallback(() => setIsOpen(!isOpen), [isOpen, setIsOpen]);

  const handleSelectItem = useCallback(
    item => () => {
      setSelectedValue(item);
      onItemSelected?.(item);
      setIsOpen(false);
    },
    [setIsOpen, setSelectedValue]
  );

  const dropdownItems = useMemo(
    () =>
      items.map(item => (
        <div
          className={classnames("dropdown-option", {
            selected: item.id == selectedValue?.id
          })}
          key={item.id}
          onClick={handleSelectItem(item)}
        >
          {item.value}
        </div>
      )),
    [handleSelectItem, items, selectedValue]
  );

  return (
    <div className="dropdown" ref={ref}>
      <div
        className={classnames("dropdown-select", { open: isOpen })}
        onClick={handleShow}
      >
        <span className="dropdown-select-selected">
          {selectedValue?.value || placeholder}
        </span>
        {isOpen ? <ArrowUp /> : <ArrowDown />}
      </div>
      <div className={classnames("dropdown-overlay", { open: isOpen })}>
        {dropdownItems}
      </div>
    </div>
  );
};

export default Dropdown;
