import React, { useMemo, useState } from "react";
import { IContent } from "../contexts/ContentContext";
import { IDropdown } from "../components/dropdown/Dropdown";
import { IDropdownItem } from "../components/dropdown/types";
import { ILocation } from "./locations";
import { IProfile } from "../components/profile/types";
import { ISearch } from "../components/search/Search";
import { ISearchItem } from "../components/search/types";
import BreadCrumbs from "../components/breadcrumbs/BreadCrumbs";
import CTABanner from "../components/banner/CTABanner";
import DoubleLineTitle from "../components/doubleLineTitle/DoubleLineTitle";
import Filter from "../components/filter/Filter";
import Page from "../components/page/Page";
import ProfileList from "../components/profile/ProfileList";
import { profilesMeta } from "./meta";
import "./profiles.css";
import { ImageAvatar } from "@metyis-ds/avatar";
import { useCheckWindowSize } from "@metyis-ds/hooks";

export default ({
  pageContext
}: {
  pageContext: { content: IContent; profiles: IProfile[] };
}) => {
  const { content, profiles } = pageContext;
  const [filterLocation, setFilterLocation] = useState("");
  const [filterPosition, setFilterPosition] = useState("");
  const [filterSearch, setFilterSearch] = useState("");
  const isMobile = useCheckWindowSize(576);

  const locations: IDropdownItem[] = useMemo(
    () => [
      {
        id: "",
        value: "All locations"
      },
      ...content.locations.map((location: ILocation) => ({
        id: location.name,
        value: location.name
      }))
    ],
    [content.locations]
  );

  const positions: IDropdownItem[] = [
    {
      id: "",
      value: "All positions"
    },
    {
      id: "Advisory Council",
      value: "Advisory Council"
    },
    {
      id: "Executive Board",
      value: "Executive Board"
    },
    {
      id: "Partner",
      value: "Partner"
    },
    {
      id: "Associate Partner",
      value: "Associate Partner"
    },
    {
      id: "Director",
      value: "Director"
    }
  ];

  const dropdowns: IDropdown[] = useMemo(
    () => [
      {
        items: locations,
        selectedItem: locations[0],
        onItemSelected: (location: IDropdownItem) =>
          setFilterLocation(location.id)
      },
      {
        items: positions,
        selectedItem: positions[0],
        onItemSelected: (position: IDropdownItem) =>
          setFilterPosition(position.id)
      }
    ],
    [locations, positions, setFilterLocation, setFilterPosition]
  );

  const filteredProfiles = useMemo(
    () =>
      profiles
        .filter(profile => !profile.shouldHideFromList)
        .filter(
          profile =>
            (filterLocation
              ? profile.coworkerLocation.toLowerCase() ===
                filterLocation.toLowerCase()
              : true) &&
            (filterPosition
              ? profile.level.toLowerCase() === filterPosition.toLowerCase()
              : true)
        ),
    [filterLocation, filterPosition, profiles]
  );

  const search: ISearch = useMemo(
    () => ({
      items: filteredProfiles.map((profile: IProfile) => ({
        id: profile.id,
        text: profile.name,
        description: `, ${profile.jobPosition}, ${profile.coworkerLocation}`
      })),
      placeholder: "Search for a senior leader",
      onItemSelected: (profile: ISearchItem | null) =>
        setFilterSearch(profile?.id || "")
    }),
    [filteredProfiles, setFilterSearch]
  );

  const searchedProfiles = useMemo(
    () =>
      filterSearch
        ? filteredProfiles.filter(
            (profile: IProfile) => profile.id === filterSearch
          )
        : filteredProfiles,
    [filteredProfiles, filterSearch]
  );

  return (
    <Page
      content={content}
      seo={{
        description: profilesMeta.description,
        title: profilesMeta.title
      }}
    >
      <BreadCrumbs
        crumbsArray={[
          {
            name: "About",
            redirectUrl: "/about"
          },
          {
            name: "Senior leaders"
          }
        ]}
      />
      <section className="profiles">
        <Filter
          dropdowns={dropdowns}
          isSticky
          mobileLabel="Filter senior leaders"
          numResults={filteredProfiles.length}
          search={search}
        />
        <div className="profile-wrapper">
          {searchedProfiles.length > 0 ? (
            positions
              .filter(position => position.id !== "")
              .map(position => {
                const positionProfiles = searchedProfiles.filter(
                  profile => profile.level === position.value
                );
                return (filterPosition === position.value ||
                  filterPosition === "") &&
                  positionProfiles.length > 0 ? (
                  <ProfileList
                    profileLevel={position.value}
                    avatarFilterColor="var(--color-green-blue)"
                    coworkers={searchedProfiles.filter(
                      profile => profile.level === position.value
                    )}
                    showContact
                  />
                ) : (
                  <></>
                );
              })
          ) : (
            <>
              <div className="profiles-header">
                <DoubleLineTitle
                  className="profiles-title"
                  title="Senior leaders"
                />
              </div>
              <ProfileList
                avatarFilterColor="var(--color-green-blue)"
                coworkers={searchedProfiles}
                showContact
              />
            </>
          )}
        </div>
        <CTABanner
          backgroundColor="var(--color-blue)"
          image={{
            file: {
              url:
                "https://images.ctfassets.net/zuzzsqg9enty/4HxuoEyyDL7uhEGWDdt3JQ/6a5eba4d847adb05454b25f55c25282b/20230619_Metyis_Campus_clean_windows_01.webp"
            },
            title: "Metyis Campus Exterior Buidlings"
          }}
          redirectUrl="/metyis-campus"
          text={{
            text:
              "Our state-of-the-art Digital Campus inspires the pursuit of innovation in a collaborative environment where partnerships and teams can evolve together."
          }}
          textColor="var(--color-brown)"
          title="We are focused on building the future"
        />
      </section>
    </Page>
  );
};
